import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import Login from './components/Login';
import LandingPage from './components/LandingPage';
import Main from './components/Main';
import MobileRedirect from './components/MobileRedirect';
import './index.css';

const App = () => {
  const [authenticated, setAuthenticated] = React.useState(false);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setAuthenticated(!!session);
    };

    checkSession();

    // Subscribe to auth state changes
    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      setAuthenticated(!!session); // Set authenticated state based on session existence
    });

    // Log the returned object to verify its structure
    console.log("Auth Listener Subscription:", data);

    // Unsubscribe from the auth state listener
    return () => {
      // Use either unsubscribe() or a different cleanup function based on the structure
      if (data && typeof data.unsubscribe === 'function') {
        data.unsubscribe(); // Correct way to unsubscribe
      } else if (data && typeof data.subscription?.unsubscribe === 'function') {
        data.subscription.unsubscribe(); // Fallback to subscription.unsubscribe
      } else {
        console.warn("Unable to unsubscribe from auth listener. Check the structure of the returned object:", data);
      }
    };
  }, []);

  // Helper function to detect if the user is on a mobile device
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  return (
    <Router>
      {/* Redirect mobile users to the MobileRedirect page */}
      {isMobileDevice() && <Navigate to="/mobile" replace />}
      <Routes>
        {/* Landing Page Route */}
        <Route path="/" element={<LandingPage />} />

        {/* Login Page Route */}
        <Route path="/login" element={authenticated ? <Navigate to="/main" /> : <Login setAuthenticated={setAuthenticated} />} />

        {/* Main Page Route */}
        <Route path="/main" element={authenticated ? <Main /> : <Navigate to="/login" />} />

        {/* Mobile Redirect Page Route */}
        <Route path="/mobile" element={<MobileRedirect />} />
      </Routes>
    </Router>
  );
};

export default App;