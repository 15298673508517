import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import Sidebar from './Sidebar';
import EntryArea from './EntryArea';
import EntriesList from './EntriesList';
import FullScreenEntry from './FullScreenEntry';
import Login from './Login';
import '../index.css'; // Import the global CSS file

const getRandomColor = () => {
  const colors = ['yellow', 'blue', 'pink'];
  return colors[Math.floor(Math.random() * colors.length)];
};

const App = () => {
  const [entries, setEntries] = useState([]);
  const [currentPageTitle, setCurrentPageTitle] = useState(''); // Store the current page title
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [notePositions, setNotePositions] = useState({}); // State to track note positions
  const [pages, setPages] = useState([]); // State to track the list of pages in Sidebar

  useEffect(() => {
    const getSession = async () => {
      const { data } = await supabase.auth.getSession();
      setAuthenticated(!!data.session);
      setUser(data.session?.user ?? null);
    };

    getSession();

    const { subscription } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        setAuthenticated(true);
        setUser(session.user);
      } else {
        setAuthenticated(false);
        setUser(null);
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  // Fetch entries and initialize their positions
  const fetchEntries = useCallback(async () => {
    if (user && currentPageTitle) {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', user.id)
        .eq('page_title', currentPageTitle) // Use page_title to filter entries by page
        .order('created_at', { ascending: false });

      if (error) {
        console.error("Error fetching entries:", error);
      } else {
        setEntries(data);

        // Set note positions based on fetched entries
        const initialPositions = {};
        data.forEach((entry) => {
          initialPositions[entry.id] = { x: entry.position_x || 0, y: entry.position_y || 0 };
        });
        setNotePositions(initialPositions); // Update the note positions state
      }
    }
  }, [user, currentPageTitle]);

  useEffect(() => {
    if (authenticated && user && currentPageTitle) {
      fetchEntries();
    }
  }, [authenticated, user, currentPageTitle, fetchEntries]);

  const postEntry = async (newEntry) => {
    if (user && currentPageTitle) {
      const randomColor = getRandomColor();

      // Insert a new entry with default position values
      const { data, error } = await supabase
        .from('notes')
        .insert([
          {
            content: newEntry,
            created_at: new Date().toISOString(),
            user_id: user.id,
            page_title: currentPageTitle, // Store the page title in the database
            color: randomColor, // Store the random color in the database
            position_x: 0, // Default initial position for new note
            position_y: 0, // Default initial position for new note
          },
        ])
        .select();

      if (error) {
        console.error("Error inserting entry:", error.message);
      } else {
        const newNote = data[0];

        // Update state with the new note entry
        setEntries((prevEntries) => [newNote, ...prevEntries]);

        // Update the positions state
        setNotePositions((prevPositions) => ({
          ...prevPositions,
          [newNote.id]: { x: 0, y: 0 },
        }));

        // Re-fetch the entries from the database to ensure all positions are up to date
        await fetchEntries(); // This will re-fetch and set the state correctly
      }
    }
  };

  const openEntry = (index) => {
    setCurrentEntry(entries[index]);
    setIsFullScreen(true);
  };

  const goBack = () => {
    setIsFullScreen(false);
    setCurrentEntry(null);
  };

  const handleDragEnd = async (event, data, entry) => {
    const { x, y } = data;

    // Update note position in the state
    setNotePositions((prevPositions) => ({
      ...prevPositions,
      [entry.id]: { x, y },
    }));

    // Update position in the Supabase database
    const { error } = await supabase
      .from('notes')
      .update({ position_x: x, position_y: y })
      .eq('id', entry.id);

    if (error) {
      console.error('Error updating note position:', error);
    }

    // Re-fetch entries after updating position to ensure database is in sync
    await fetchEntries();
  };

  // Updated deletePage function to refresh the sidebar dynamically
  const deletePage = async () => {
    if (currentPageTitle && user) {
      console.log(`Deleting page: ${currentPageTitle}`);

      const { error: deleteError } = await supabase
        .from('notes')
        .delete()
        .eq('user_id', user.id)
        .eq('page_title', currentPageTitle);

      if (deleteError) {
        console.error("Error deleting page entries:", deleteError.message);
      } else {
        console.log(`Page '${currentPageTitle}' and its entries have been deleted.`);
        setCurrentPageTitle('');
        setEntries([]);
        setNotePositions({}); // Clear note positions

        // Remove deleted page from sidebar state immediately
        setPages((prevPages) => prevPages.filter((page) => page.page_title !== currentPageTitle));
      }
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setAuthenticated(false);
    setUser(null);
    setEntries([]);
  };

  return (
    <div className="app-container">
      {authenticated ? (
        <>
          <Sidebar
            user={user}
            setCurrentPageTitle={setCurrentPageTitle}
            currentPageTitle={currentPageTitle} // Pass currentPageTitle to Sidebar
            pages={pages} // Pass pages state
            setPages={setPages} // Pass setPages function to manipulate pages state
          />
          <div className="top-right-buttons">
            <button className="logout-button" onClick={handleLogout}>
              Log Out
            </button>
            <button className="delete-page-button" onClick={deletePage} disabled={!currentPageTitle}>
              Delete Page
            </button>
          </div>
          <div className="main-content">
            {currentPageTitle ? (
              <>
                <EntriesList
                  entries={entries}
                  openEntry={openEntry}
                  handleDragEnd={handleDragEnd} // Pass handleDragEnd to EntriesList
                />
              </>
            ) : (
              <p>Please select a page or create a new one.</p>
            )}
          </div>
          {isFullScreen && currentEntry && (
            <FullScreenEntry
              entry={currentEntry}
              goBack={goBack}
              pageTitle={currentPageTitle}
            />
          )}
          {!isFullScreen && (
            <div className="fixed-entry-area">
              <EntryArea postEntry={postEntry} />
            </div>
          )}
        </>
      ) : (
        <Login setAuthenticated={setAuthenticated} />
      )}
    </div>
  );
};

export default App;