import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import './Sidebar.css';

const Sidebar = ({ user, setCurrentPageTitle, currentPageTitle, pages, setPages }) => {
  const [newPageTitle, setNewPageTitle] = useState('');

  // Function to fetch distinct page titles from the notes table
  const fetchPages = async () => {
    if (user) {
      const { data, error } = await supabase
        .from('notes')
        .select('page_title') // Select distinct page titles
        .eq('user_id', user.id)
        .neq('page_title', null) // Ensure no null values
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching pages:', error);
      } else {
        // Get unique page titles
        const uniquePages = Array.from(new Set(data.map((note) => note.page_title)));
        setPages(uniquePages.map((title) => ({ page_title: title })));
      }
    }
  };

  useEffect(() => {
    fetchPages(); // Fetch pages when the component mounts
  }, [user]);

  // Function to create a new page
  const createPage = async () => {
    if (newPageTitle.trim() !== '') {
      // Add the new page to local state immediately
      const uniquePageTitle = generateUniquePageTitle(newPageTitle);
      setPages([{ page_title: uniquePageTitle }, ...pages]); // Update pages with the new page
      setCurrentPageTitle(uniquePageTitle); // Set the newly created page as current
      setNewPageTitle(''); // Clear the input field
    }
  };

  // Generate a unique page title if a page with the same title already exists
  const generateUniquePageTitle = (baseTitle) => {
    let uniqueTitle = baseTitle;
    let counter = 1;
    while (pages.some((page) => page.page_title === uniqueTitle)) {
      uniqueTitle = `${baseTitle} ${counter}`;
      counter += 1;
    }
    return uniqueTitle;
  };

  // Remove a page from the local state after deletion
  const removePageFromSidebar = (pageTitle) => {
    setPages((prevPages) => prevPages.filter((page) => page.page_title !== pageTitle));
  };

  return (
    <div className="sidebar">
      <h2>Your Pages</h2>
      <div className="pages-list">
        {pages.map((page) => (
          <div
            key={page.page_title}
            className={`page-item ${page.page_title === currentPageTitle ? 'selected-page' : ''}`}
            onClick={() => setCurrentPageTitle(page.page_title)} // Pass page_title to setCurrentPageTitle
          >
            {page.page_title}
          </div>
        ))}
      </div>
      <div className="new-page">
        <input
          type="text"
          value={newPageTitle}
          onChange={(e) => setNewPageTitle(e.target.value)}
          placeholder="New page title"
        />
        <button onClick={createPage}>Create Page</button>
      </div>
    </div>
  );
};

export default Sidebar;