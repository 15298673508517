import React from 'react';

const FullScreenEntry = ({ entry, goBack, pageTitle }) => {
  return (
    <div className="full-screen">
      <div className="entry-content">
        {/* Display the page title as the note's title */}
        
        <h2>{pageTitle}</h2>


        <p>{entry.content}</p>
        <small>{new Date(entry.created_at).toLocaleString()}</small>


      </div>

      {/* Back button to go back to the main view */}
      <button className="back-button" onClick={goBack}>Back</button>

    </div>
  );
};

export default FullScreenEntry;