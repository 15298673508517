import React, { useState } from 'react';
import './EntryArea.css'; // Import the CSS file

const EntryArea = ({ postEntry }) => {
  const [newEntry, setNewEntry] = useState('');

  const handlePost = () => {
    if (newEntry.trim() !== '') {
      postEntry(newEntry);
      setNewEntry(''); // Clear the input after posting
    }
  };

  return (
    <div className="entry-area-container">
      <textarea
        value={newEntry}
        onChange={(e) => setNewEntry(e.target.value)}
        placeholder="Write a new note..."
      />
      {/* Button with an up arrow icon */}
      <button onClick={handlePost} className="post-button">
        ↑ {/* Up arrow icon */}
      </button>
    </div>
  );
};

export default EntryArea;