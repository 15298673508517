// src/components/MobileRedirect.js
import React from 'react';
import './MobileRedirect.css'; // Create this CSS file for styling

const MobileRedirect = () => {
  return (
    <div className="mobile-redirect">
      <div className="sticky-note-mobile">
        <p>This site is desktop only</p>
      </div>
    </div>
  );
};

export default MobileRedirect;