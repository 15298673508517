// src/components/Login.js

import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import './Login.css';

const Login = ({ setAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Function to handle email and password login
  const handleLogin = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setError('Login failed. Please check your credentials.');
    } else {
      setError('');
      setAuthenticated(true);
    }
  };

  // Function to handle email and password signup
  const handleSignup = async () => {
    const { error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error) {
      setError('Signup failed. Please try again.');
    } else {
      setError('');
      setAuthenticated(true);
    }
  };

  // Function to handle Twitter sign-in
  const handleTwitterLogin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'twitter',
    });

    if (error) {
      console.error('Error signing in with Twitter:', error.message);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form">
        <h2>Login</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password - Must be at least 8 characters"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="button" onClick={handleLogin}>Login</button>
        <button type="button" onClick={handleSignup}>Sign Up</button>
        
    

        {error && <p className="error">{error}</p>}
      </form>
      <div className="footer-text">
        Stick Notes - 2024
      </div>
    </div>
  );
};

export default Login;