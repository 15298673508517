import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import './EntriesList.css';
import { supabase } from '../supabaseClient';

const EntriesList = ({ entries, openEntry, handleDragEnd }) => {
  // State to track local note positions
  const [notePositions, setNotePositions] = useState({});
  const [isDragging, setIsDragging] = useState(false); // Track whether an item is being dragged
  const [mouseDown, setMouseDown] = useState(false); // Track mouse down state to distinguish click vs drag

  // Effect to initialize note positions based on `entries` data
  useEffect(() => {
    const positions = {};
    entries.forEach((entry) => {
      positions[entry.id] = { x: entry.position_x, y: entry.position_y };
    });
    setNotePositions(positions);
  }, [entries]); // Runs whenever `entries` changes

  // Handle the drag start event
  const handleDragStart = () => {
    setIsDragging(true);
    setMouseDown(true);
  };

  // Handle the drag stop event to update note positions in the database
  const handleDragStop = async (e, data, entry) => {
    setTimeout(() => {
      setIsDragging(false);
      setMouseDown(false);
    }, 100); // Small delay to avoid triggering click event right after drag

    // Update the local state with the new position
    setNotePositions((prevPositions) => ({
      ...prevPositions,
      [entry.id]: { x: data.x, y: data.y },
    }));

    // Update the position in the Supabase database
    const { error } = await supabase
      .from('notes')
      .update({ position_x: data.x, position_y: data.y })
      .eq('id', entry.id);

    if (error) {
      console.error('Error updating position:', error);
    }
  };

  // Prevent opening the note if it was dragged
  const handleClick = (index) => {
    if (!isDragging && !mouseDown) {
      openEntry(index);
    }
  };

  return (
    <div className="entries-list">
      {entries.length > 0 ? (
        entries.map((entry, index) => (
          <Draggable
            key={entry.id} // Use entry.id as key to ensure unique identification
            position={notePositions[entry.id]} // Use stored local position
            onStart={handleDragStart}
            onStop={(e, data) => handleDragStop(e, data, entry)} // Update the position when drag stops
            onDrag={(e, data) => {
              // Update position during drag in local state
              setNotePositions((prevPositions) => ({
                ...prevPositions,
                [entry.id]: { x: data.x, y: data.y },
              }));
            }}
          >
            <div
              className={`entry-item post-it-note ${entry.color}`} // Use stored color for the note
              onClick={() => handleClick(index)} // Handle click with improved logic
              style={{ position: 'absolute' }} // Ensure notes are positioned absolutely
            >
              <div className="note-content">
                {entry.content}
              </div>
              <small className="entry-date">
                {new Date(entry.created_at).toLocaleString()}
              </small>
            </div>
          </Draggable>
        ))
      ) : (
        <p className="no-entries-text">No notes to display.</p>
      )}
    </div>
  );
};

export default EntriesList;