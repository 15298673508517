// src/components/CustomLandingPage.js

import React from 'react';
import './LandingPage.css'; // Import the corresponding CSS file

const CustomLandingPage = () => {
  return (
    <div>
      <main>
        <section className="hero">
          <h1><span className="coral-blue">Sticky Notes</span></h1>
        </section>
        <section className="hero">
          <a href="/login" className="cta-button">Login / Sign Up</a>
        </section>

        {/* You can add back other sections if needed, or leave them out */}
        <section className="features">
          <h3>Get Organized, Stay Productive</h3>
          <p>We bring simplicity to your digital note-taking experience.</p>
            
          <div className="feature-grid">
            <div className="feature-item">
              <div className="feature-icon">
                <i className="fas fa-sticky-note"></i>
              </div>
              <h4>Sticky Notes</h4>
              <p>Create sticky notes on your digital board, drag them around, and organize them as you like</p>
            </div>
            <div className="feature-item-2">
              <div className="feature-icon">
                <i className="fas fa-folder"></i>
              </div>
              <h4>Organized Pages</h4>
              <p>Manage your notes across multiple pages, each with a unique title and notes</p>
            </div>
            <div className="feature-item-3">
              <div className="feature-icon">
                <i className="fas fa-share-alt"></i>
              </div>
              <h4>Share & Collaborate</h4>
              <p>Invite others to view or collaborate on your notes and organize your thoughts together - COMING SOON</p>
            </div>
          </div>
        </section>

        <footer>
          <div className="footer-content">
            <div className="footer-main">
              <p>Sticky Notes - 2024</p>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default CustomLandingPage;